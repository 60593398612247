import React, { useState } from "react";
import {
  DesktopOutlined,
  FileOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { IoNotifications } from "react-icons/io5";

import {
  Layout as AntdLayout,
  Breadcrumb,
  Layout,
  Menu,
  Dropdown,
  Avatar,
  message,
  Button,
} from "antd";
import { NavLink, useLocation } from "react-router-dom";
import _ from "lodash";

import "./layout.less";
import Logo from "@assets/images/logoD.png";
import LogoM from "@assets/images/logoM.png";
import { ReactComponent as Down } from "@assets/images/arrow-down.svg";
import { ReactComponent as Bell } from "@assets/images/bell.svg";
import { IConfig, client_config } from "./sidebar-config";
import profilePic from "@assets/images/avatar.png";
import { useTypedSelector } from "@hooks";

import {
  ErrorServices,
  loadRefreshToken,
  // useFetchCompanyJobGradesQuery,
  useLogoutMutation,
} from "@services";
import { checkPermission, isAdmin } from "@utils";

const ssoUrl = "";
const appsPageUrl = "/#/app";

const { Header, Content, Footer, Sider } = Layout;

const { SubMenu } = Menu;

const App = ({ children }: { children: React.ReactNode }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [currentPath, setCurrentPath] = useState<string>("/app");
  const { user } = useTypedSelector(state => state.auth);
  const { id: company_id } = user?.company || {};
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [onLogout] = useLogoutMutation();
  const { pathname } = useLocation();

  const config = client_config(
    [
      {
        grade: "",
        type: null,
        country_ids: [1],
        countries: [
          {
            id: 1,
            name: "",
          },
        ],
        id: 1,
        is_global: true,
      },
    ],
    company_id?.toString() || "1",
    `${user?.role}`
  );

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    path: string | Array<string>,
    disabled?: boolean | undefined,
    fallbackMessage?: string | undefined,
    d?: string
  ) => {
    setCurrentPath(d || "");
    if (Array.isArray(path)) {
      event.preventDefault();
      if (disabled) message.error(fallbackMessage);
    }
  };

  const getRoute = (path: string | Array<string>): string => {
    if (typeof path == "string") return path;
    else if (
      Array.isArray(path) &&
      path.includes(pathname) &&
      typeof pathname == "string"
    )
      return pathname;
    else return "";
  };

  const onLogoutFromServer = async () => {
    try {
      const refresh = loadRefreshToken();
      await onLogout({ refresh }).unwrap();
      window.open(ssoUrl, "_self"); // open SSO page after logout
    } catch (error) {
      ErrorServices(error);
    }
  };

  const openAppsPage = () => {
    window.open(appsPageUrl, "_self");
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={openAppsPage}>
        Apps
      </Menu.Item>
      <Menu.Item key="2" onClick={onLogoutFromServer}>
        Log out
      </Menu.Item>
    </Menu>
  );

  let colorBgContainer = "red";

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        className="bg-themeCore shadow-md"
        width={250}
      >
        <div className="demo-logo-vertical" />
        <div className="mb-6">
          <img
            onClick={() => setCollapsed(!collapsed)}
            src={collapsed ? LogoM : Logo}
            className=" w-full h-full px-4 py-4 cursor-pointer"
          />
        </div>
        <div>
          {config?.map((config: IConfig, idx) => {
            return (
              <div className="py-1 mx-1" key={idx}>
                {console.log(getRoute(config.path), currentPath)}
                <NavLink
                  exact={true}
                  key={idx}
                  to={getRoute(config.path)}
                  className={`group font-medium hover:no-underline visited:text-black flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-themeBlue hover:text-white  ${
                    getRoute(config.path) !== currentPath
                      ? "text-sm font-medium text-themeGrey"
                      : ""
                  } 
                  ${collapsed ? "flex justify-center" : ""}
                  transition-all delay-[1ms]`}
                  activeClassName="bg-themeBlue font-medium  text-white visited:text-white text-white !no-underline"
                  onClick={event => {
                    handleLinkClick(
                      event,
                      config.path,
                      config?.disabled,
                      config?.fallbackMessage,
                      getRoute(config.path)
                    );
                  }}
                >
                  <div
                    className={`${collapsed ? "" : "mr-4"} ${
                      config?.client && ""
                    }`}
                  >
                    <config.icon className="" />
                  </div>
                  <p>{!collapsed && config.title}</p>
                </NavLink>
                {!config?.disabled &&
                  config.sub?.map((subLink: any, i: number) =>
                    checkPermission(subLink?.permission) ? (
                      <NavLink
                        key={i}
                        to={subLink.path || ""}
                        className="sider__sub__link"
                        activeClassName="sider__active"
                        onClick={event => handleLinkClick(event, subLink.path)}
                      >
                        <div
                          className={`sider__icon__container ${
                            config?.client && "sider__icon__container--client"
                          }`}
                        >
                          <subLink.icon className="sider__link__icon" />
                        </div>
                        {subLink.title}
                      </NavLink>
                    ) : null
                  )}
              </div>
            );
          })}
        </div>

        <div className="absolute bottom-10 items-center justify-center flex w-full">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
        </div>
      </Sider>
      <Layout>
        <Header className="bg-themeCore flex justify-end items-center shadow-sm">
          {/* <Bell className="header__notif__icon" /> */}
          <div className="notification relative mr-4">
            <div
              style={{
                backgroundColor: "#00519d",
              }}
              className="notificationDot rounded-full  p-1 w-1 h-1 absolute top-0 right-1 border-2"
            ></div>
            <div className="bell">
              <IoNotifications
                style={{
                  color: "#6b6d70",
                }}
                className="text-3xl "
              />
            </div>
          </div>
          <Dropdown
            overlay={menu}
            onVisibleChange={open => setIsMenuVisible(open)}
            visible={isMenuVisible}
            trigger={["click"]}
          >
            <div className="profile__menu__toggle">
              <Avatar size={32} src={profilePic} />
              <div className="flex flex-col mr-6 ml-3">
                <span className="leading-17.1 font-semibold text-sm">{`${user?.first_name} ${user?.last_name}`}</span>
                <span className="leading-14.6 font-medium text-xs">
                  {_.replace(_.startCase(_.toLower(user?.role)), "_", " ")}
                </span>
              </div>
              <div className="h-auto leading-none">
                <Down className="" />
              </div>
            </div>
          </Dropdown>
        </Header>
        <Content
          style={{
            padding: "20px 16px",
            width: "100%",
            height: "calc(100vh - 64px)",
          }}
        >
          <div style={{ overflowY: "auto", maxHeight: "100%" }}>
            <div className=" mt-[30%]">{children}</div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
