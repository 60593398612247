import { useEffect, useState } from "react";
// import Table from "@components/Table";
import { Switch } from "antd";
import { useParams } from "react-router-dom";
import "./style.less";
import {
  useFetchAllBusinessUnitsQuery,
  useFetchJobGradesByCountryQuery,
  useFetchAllJobFunctionsQuery,
} from "@services";
import { useTypedSelector } from "@hooks";
import PermissionsTable from "../PermissionTable";
import {
  IBusinessUnitItem,
  ICountry,
  IJobFunctionItem,
  IJobGrade,
  IRegion,
} from "@types";
import Select from "react-select";



type RolePermissions = {
  [role: string]: string[];
};

const rolePermissions: RolePermissions = {
  "TOM_MASTER_USER": ["VIEW_BUSINESS_UNIT", "UPDATE_BUSINESS_UNIT", "CREATE_BUSINESS_UNIT"],
  "TOM_SUPER_USER": ["VIEW_REGION", "UPDATE_REGION", "CREATE_JOB_EVALUATION"],
  "TOM_ADMIN": ["CREATE_REGION", "DELETE_REGION", "UPDATE_JOB_EVALUATION"],
  "TOM_SALES": ["VIEW_JOB_EVALUATION", "VIEW_REGION", "CREATE_BUSINESS_UNIT"],
  "COMPANY_SUPER_USER": ["CREATE_BUSINESS_UNIT", "UPDATE_BUSINESS_UNIT", "DELETE_BUSINESS_UNIT"],
  "COMPANY_ADMIN": ["UPDATE_REGION", "VIEW_JOB_EVALUATION", "CREATE_JOB_EVALUATION"],
  "COMPANY_USER": ["VIEW_BUSINESS_UNIT", "VIEW_REGION", "VIEW_JOB_EVALUATION"],
};

const JeTab = ({
  JeData,
  createDataCallback,
}: {
  JeData: any;
  createDataCallback: (key: string, data: any) => void;
}) => {
  const { user } = useTypedSelector(state => state.auth);
  const [businessUnitState, setBusinessUnitState] = useState<any[]>([]);
  const [regionState, setRegionState] = useState<any[]>([]);
  const [regions, setRegions] = useState<Partial<IRegion>[]>([]);
  const [countryState, setCountryState] = useState<any[]>([]);
  const [countries_id, setCountryIdState] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [grade, setGrade] = useState<any[]>([]);
  const [jobFunctionState, setJobFunctionState] = useState<any[]>([]);
  const [role, setRole] = useState<string>("");
  const [isCustomRole, setIsCustomRole] = useState<boolean>(false);
  const [firstTimeData, setFirstTimeData] = useState<boolean>(true);
  const [setData, setSetData] = useState<boolean>(false);
  const [permissionArray, setPermissionArray] = useState<string[]>([]);
  const [permissionAPIArray, setPermissionAPIArray] = useState<string[]>([]);
  const [accessGranted, setAccessGranted] = useState<boolean>(false);
  const params: { company_id: string } = useParams();
  const paramsCompanyId = params.company_id;

  const { id: company_id } = user?.company || { id: paramsCompanyId };
  const { data: businessUnitsData, isLoading: isLoadingBusinessUnits } =
    useFetchAllBusinessUnitsQuery({ company_id });
  const { data: businessUnits } = businessUnitsData || {};
  const { data: gradesData, isLoading: isLoadingGrades } =
    useFetchJobGradesByCountryQuery(
      { company_id, countries_id },
      { refetchOnMountOrArgChange: true }
    );
  const { data: grades } = gradesData || {};
  const { data: jobFunctionsData, isLoading: isLoadingJobFunctions } =
    useFetchAllJobFunctionsQuery(null);
  const { data: jobFunctions } = jobFunctionsData || {};

  useEffect(
    () => {
      if (
        JeData &&
        !isLoadingBusinessUnits &&
        !isLoadingGrades &&
        !isLoadingJobFunctions &&
        firstTimeData
      ) {
        const apiBusiness = JeData?.metafield?.business_units
          ? [...JeData?.metafield.business_units.map((elm: any) => elm)]
          : [];
        const extractRegionsFromBU = businessUnits.filter((elm: any) =>
          apiBusiness.includes(elm.id)
        );
        var tempRegions: string[] = [];
        var tempCountries: string[] = [];
        extractRegionsFromBU.forEach((elm: any) => {
          tempRegions.push(...elm.regions);
        });
        var distinctRegionsArr: object[] = Object.values(
          tempRegions.reduce((a: any, b: any) => {
            if (!a[b.id]) a[b.id] = b;
            return a;
          }, {})
        );
        setRegions(distinctRegionsArr);
        distinctRegionsArr.forEach((elm: any) => {
          tempCountries.push(...elm.countries);
        });
        var distinctCountriesArr: object[] = Object.values(
          tempCountries.reduce((a: any, b: any) => {
            if (!a[b.id]) a[b.id] = b;
            return a;
          }, {})
        );
        setCountries(distinctCountriesArr);

        setBusinessUnitState(apiBusiness);
        setRegionState(
          JeData?.metafield.regions ? [...JeData?.metafield.regions] : []
        );
        setCountryState(
          JeData?.metafield.countries ? [...JeData?.metafield.countries] : []
        );
        setGrade(JeData?.metafield.grades ? [...JeData?.metafield.grades] : []);
        setJobFunctionState(
          JeData?.metafield.job_functions
            ? [...JeData?.metafield.job_functions]
            : []
        );
        setPermissionAPIArray(JeData?.permissions);
        setCountryIdState(
          JeData?.metafield.countries ? [...JeData?.metafield.countries] : []
        );
        setAccessGranted(JeData?.grant_access);

        if (JeData?.name === "COMPANY_SUPER_USER") {
          setRole(JeData?.name);
        } else {
          setIsCustomRole(true);
          setRole(JeData?.name);
        }
        if (JeData?.name) setFirstTimeData(false);
      }
    },
    // eslint-disable-next-line
    [
      JeData,
      isLoadingBusinessUnits,
      isLoadingGrades,
      isLoadingJobFunctions,
      firstTimeData,
    ]
  );

  useEffect(() => {
    if (setData) {
      handleCreateData();
      setSetData(false);
    }
    // eslint-disable-next-line
  }, [setData]);


  const getPermissionsForRole = (role: string): string[] => {
    return rolePermissions[role] || [];
  };
  
  
  const handleCreateData = () => {
    try {
      let data = { ...JeData };
      data = {
        grant_access: accessGranted,
        // role: role,
        name: role,
        description: role,
        metafield: {
          business_units: businessUnitState,
          regions: regionState,
          countries: countryState,
          grades: grade,
          job_functions: jobFunctionState,
        },
        permissions: [...permissionArray],
      };
      createDataCallback("je", data);
    } catch (error) {}
  };

  const handleChange = (newValue: any) => {
    if (newValue?.length) {
      var tempRegions: string[];
      tempRegions = [];
      setBusinessUnitState(newValue.map((elm: any) => elm.value));

      const tempBu = newValue.map((elm: any) => elm.label);
      businessUnits
        .filter((item: any) => tempBu.includes(item.name))
        .forEach((elm: any) => {
          tempRegions.push(...elm.regions);
        });

      var distinctArr: object[] = Object.values(
        tempRegions.reduce((a: any, b: any) => {
          if (!a[b.id]) a[b.id] = b;
          return a;
        }, {})
      );
      setRegionState([]);
      setCountryState([]);

      setRegions(distinctArr);
    } else {
      setCountries([]);
      setCountryState([]);
      setBusinessUnitState([]);
      setRegions([]);
      setRegionState([]);
    }
    setSetData(true);
  };

  const handleRegionChange = (newValue: any) => {
    if (newValue?.length) {
      var tempCountries: string[];
      tempCountries = [];
      setRegionState(newValue.map((elm: any) => elm.value));

      const tempRe = newValue.map((elm: any) => elm.label);
      regions
        .filter((item: any) => tempRe.includes(item.name))
        .forEach((elm: any) => {
          tempCountries.push(...elm.countries);
        });
      var distinctArr: object[] = Object.values(
        tempCountries.reduce((a: any, b: any) => {
          if (!a[b.id]) a[b.id] = b;
          return a;
        }, {})
      );
      setCountryState([]);
      setCountries(distinctArr);
    } else {
      setCountries([]);
      setCountryState([]);
      setRegionState([]);
    }
    setSetData(true);
  };
  const handleCountryChange = (newValue: any) => {
    if (newValue?.length) {
      const tempCountries = newValue?.map((elm: any) => elm.value);

      setCountryState(tempCountries);
      setCountryIdState(tempCountries);
    } else {
      setGrade([]);
      setCountryIdState([]);
      setCountryState([]);
    }
    setSetData(true);
  };

  const changeGrantedAccess = () => {
    setAccessGranted(!accessGranted);
    setSetData(true);
  };

  return (
    <>
      <div className="sub__admin_details_container">
        <div style={{ padding: "32px 20px 12px" }}>
          <div
            style={{
              backgroundColor: "#FAFAFA",
              padding: "16px",
              borderRadius: "8px",
              width: "max-content",
            }}
          >
            <span style={{ fontWeight: 500, marginRight: "16px" }}>
              Grant Access
            </span>
            <Switch checked={accessGranted} onChange={changeGrantedAccess} />
          </div>
        </div>

        <div className="form__section">
          <div className="form__section_container">
            <div className="contact__person__sub_container">
              <div
                style={{
                  width: "50%",
                  marginRight: "50px",
                  marginBottom: "33px",
                }}
              >
                <label
                  className="input__label"
                  style={{ marginBottom: "24px" }}
                >
                  Choose Business Units
                </label>
                <Select
                  isMulti
                  isDisabled={role === "COMPANY_SUPER_USER" ? true : false}
                  placeholder="Search and select business units"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={businessUnits
                    ?.filter((elm: Partial<IBusinessUnitItem>) =>
                      businessUnitState.includes(elm?.id)
                    )
                    ?.map((businessUnit: Partial<IBusinessUnitItem>) => ({
                      label: businessUnit?.name,
                      value: businessUnit?.id,
                    }))}
                  options={businessUnits?.map(
                    (businessUnit: Partial<IBusinessUnitItem>) => ({
                      label: businessUnit?.name,
                      value: businessUnit?.id,
                    })
                  )}
                  onChange={handleChange}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  marginRight: "50px",
                  marginBottom: "33px",
                }}
              >
                <label
                  className="input__label"
                  style={{ marginBottom: "24px" }}
                >
                  Choose a Region
                </label>
                <Select
                  isMulti
                  isDisabled={
                    (role === "COMPANY_SUPER_USER" ? true : false) ||
                    !businessUnitState?.length
                  }
                  placeholder="Search and select regions"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={regions
                    ?.filter((elm: Partial<IRegion>) =>
                      regionState.includes(elm?.id)
                    )
                    ?.map((region: Partial<IRegion>) => ({
                      label: region?.name,
                      value: region?.id,
                    }))}
                  options={regions?.map((region: Partial<IRegion>) => ({
                    label: region?.name,
                    value: region?.id,
                  }))}
                  onChange={handleRegionChange}
                />
              </div>
            </div>

            <div className="contact__person__sub_container">
              <div
                style={{
                  width: "50%",
                  marginRight: "50px",
                  marginBottom: "33px",
                }}
              >
                <label
                  className="input__label"
                  style={{ marginBottom: "24px" }}
                >
                  Choose a Country
                </label>
                <Select
                  isMulti
                  isDisabled={
                    (role === "COMPANY_SUPER_USER" ? true : false) ||
                    !regionState?.length
                  }
                  placeholder="Search and select Country"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={countries
                    ?.filter((country: ICountry) =>
                      countryState.includes(country?.id)
                    )
                    ?.map((country: ICountry) => ({
                      label: country?.name,
                      value: country?.id,
                    }))}
                  options={countries?.map((country: ICountry) => ({
                    label: country?.name,
                    value: country?.id,
                  }))}
                  onChange={handleCountryChange}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  marginRight: "50px",
                  marginBottom: "4px",
                }}
              >
                <label
                  className="input__label"
                  style={{ marginBottom: "24px" }}
                >
                  Role
                </label>
                {!isCustomRole ? (
                  <Select
                    placeholder="Select role from here..."
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={[
                      {
                        label: "COMPANY_SUPER_USER",
                        value: "COMPANY_SUPER_USER",
                      },
                      {
                        label: "TOM_MASTER_USER",
                        value: "TOM_MASTER_USER",
                      },
                      {
                        label: "TOM_SUPER_USER",
                        value: "TOM_SUPER_USER",
                      },
                      {
                        label: "TOM_ADMIN",
                        value: "TOM_ADMIN",
                      },
                      {
                        label: "TOM_SALES",
                        value: "TOM_SALES",
                      },

                      {
                        label: "COMPANY_ADMIN",
                        value: "COMPANY_ADMIN",
                      },
                      {
                        label: "COMPANY_USER",
                        value: "COMPANY_USER",
                      },
                      {
                        label: "COMPANY_BUSINESS_ACCESS",
                        value: "COMPANY_BUSINESS_ACCESS",
                      },
                    ].filter((elm: any) => role === elm.value)}
                    onChange={(newValue: any) => {
                      setRole(newValue?.value);
                      const permissions  = getPermissionsForRole(newValue?.value);
                      setPermissionAPIArray(permissions)
                      setSetData(true);

                    }}
                    options={[
                      {
                        label: "COMPANY_SUPER_USER",
                        value: "COMPANY_SUPER_USER",
                      },
                      {
                        label: "TOM_MASTER_USER",
                        value: "TOM_MASTER_USER",
                      },
                      {
                        label: "TOM_SUPER_USER",
                        value: "TOM_SUPER_USER",
                      },
                      {
                        label: "TOM_ADMIN",
                        value: "TOM_ADMIN",
                      },
                      {
                        label: "TOM_SALES",
                        value: "TOM_SALES",
                      },

                      {
                        label: "COMPANY_ADMIN",
                        value: "COMPANY_ADMIN",
                      },
                      {
                        label: "COMPANY_USER",
                        value: "COMPANY_USER",
                      },
                      {
                        label: "COMPANY_BUSINESS_ACCESS",
                        value: "COMPANY_BUSINESS_ACCESS",
                      },
                    ]}
                  />
                ) : (
                  <input
                    style={{
                      border: "0.75px solid #d9d9d9",
                      padding: "0.5rem 11px",
                      borderRadius: "2px",
                    }}
                    name="je_role"
                    type="text"
                    placeholder="Enter custom role here..."
                    value={role}
                    onChange={e => {
                      setRole(e.target.value);
                      setSetData(true);
                    }}
                  />
                )}
                {!isCustomRole ? (
                  <div
                    className="change_role"
                    onClick={() => {
                      setIsCustomRole(true);
                      setRole("");
                    }}
                  >
                    Create custom role
                  </div>
                ) : (
                  <div
                    className="change_role"
                    onClick={() => {
                      setIsCustomRole(false);
                      setRole("");
                    }}
                  >
                    Select from default roles
                  </div>
                )}
              </div>
              {/* 
              <div
                style={{
                  width: "50%",
                  marginRight: "50px",
                  marginBottom: "33px",
                }}
              >
                <label
                  className="input__label"
                  style={{ marginBottom: "24px" }}
                >
                  Choose Grades
                </label>
                <Select
                  isMulti
                  isDisabled={
                    (role === "COMPANY_SUPER_USER" ? true : false) ||
                    !countryState?.length
                  }
                  placeholder="Search and select Grades"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={grades
                    ?.filter((elm: Partial<IJobGrade>) =>
                      grade.includes(elm?.id)
                    )
                    ?.map((grade: Partial<IJobGrade>) => ({
                      label: grade?.grade,
                      value: grade?.id,
                    }))}
                  options={grades?.map((grade: Partial<IJobGrade>) => ({
                    label: grade?.grade,
                    value: grade?.id,
                  }))}
                  onChange={(newValue: any) => {
                    setGrade(newValue?.map((elm: any) => elm.value));
                    setSetData(true);
                  }}
                />
              </div> */}
            </div>

            <div className="contact__person__sub_container">
              {/* <div
                style={{
                  width: "50%",
                  marginRight: "50px",
                  marginBottom: "33px",
                }}
              >
                <label
                  className="input__label"
                  style={{ marginBottom: "24px" }}
                >
                  Job Functions
                </label>
                <Select
                  isMulti
                  isDisabled={role === "COMPANY_SUPER_USER" ? true : false}
                  placeholder="Search and select Job Functions"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={jobFunctions
                    ?.filter((jobFunction: IJobFunctionItem) =>
                      jobFunctionState.includes(jobFunction?.id)
                    )
                    ?.map((jobFunction: IJobFunctionItem) => ({
                      label: jobFunction?.name,
                      value: jobFunction?.id,
                    }))}
                  options={jobFunctions?.map(
                    (jobFunction: IJobFunctionItem) => ({
                      label: jobFunction?.name,
                      value: jobFunction?.id,
                    })
                  )}
                  onChange={(newValue: any) => {
                    setJobFunctionState(newValue?.map((elm: any) => elm.value));
                    setSetData(true);
                  }}
                />
              </div> */}
              {/* 
              <div
                style={{
                  width: "50%",
                  marginRight: "50px",
                  marginBottom: "4px",
                }}
              >
                <label
                  className="input__label"
                  style={{ marginBottom: "24px" }}
                >
                  Role
                </label>
                {!isCustomRole ? (
                  <Select
                    placeholder="Select role from here..."
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={[
                      {
                        label: "COMPANY_SUPER_USER",
                        value: "COMPANY_SUPER_USER",
                      },
                    ].filter((elm: any) => role === elm.value)}
                    onChange={(newValue: any) => {
                      setRole(newValue?.value);
                      setSetData(true);
                    }}
                    options={[
                      {
                        label: "COMPANY_SUPER_USER",
                        value: "COMPANY_SUPER_USER",
                      },
                    ]}
                  />
                ) : (
                  <input
                    style={{
                      border: "0.75px solid #d9d9d9",
                      padding: "0.5rem 11px",
                      borderRadius: "2px",
                    }}
                    name="je_role"
                    type="text"
                    placeholder="Enter custom role here..."
                    value={role}
                    onChange={e => {
                      setRole(e.target.value);
                      setSetData(true);
                    }}
                  />
                )}
                {!isCustomRole ? (
                  <div
                    className="change_role"
                    onClick={() => {
                      setIsCustomRole(true);
                      setRole("");
                    }}
                  >
                    Create custom role
                  </div>
                ) : (
                  <div
                    className="change_role"
                    onClick={() => {
                      setIsCustomRole(false);
                      setRole("");
                    }}
                  >
                    Select from default roles
                  </div>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="sub__admin_details_container">
        <h1 className="section__heading margin__bottom__0">
          Permission Settings
        </h1>
        <div className="form__section">
          <div className="form__section_container">
            <PermissionsTable
              role={role === "COMPANY_SUPER_USER" ? true : false}
              setCallback={(val: any) => {
                setPermissionArray(val);
                setSetData(true);
              }}
              permissionAPIArray={permissionAPIArray}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default JeTab;
