import "@styles/index.less";
import { routeConfig, Routes } from "@router";
import Layout from "@components/Layout";
import { useEffect } from "react";
import { cssVars } from "./constants";
import { useLocation } from "react-router-dom";
import Layout2 from "./components/Layout2";
import { useTypedSelector } from "@hooks";
import { useDispatch } from "react-redux";
import { fetchCountries } from "@services";
import "./styles.less";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

declare global {
  interface Window {
    less: any;
  }
}

const App = () => {
  const dispatch = useDispatch();
  const { countries } = useTypedSelector(state => state.countries);
  const { user } = useTypedSelector(state => state.auth);
  const location = useLocation();

  useEffect(() => {
    if (!countries?.length && user?.id) {
      dispatch(fetchCountries());
    }
  }, [dispatch, countries, user]);

  useEffect(() => {
    if (window?.less?.modifyVars) {
      window?.less?.modifyVars(cssVars);
    }
  }, []);

  const paths = [
    // "/app", "/sub-admins", "/sub-admins/create", "/sub-admins/edit"
    "/",
    "/login",
    "/forgot-password",
    "/reset-password",
  ];

  if (!paths.includes(location.pathname)) {
    return (
      <Layout2>
        <Routes routes={routeConfig} />
      </Layout2>
    );
  } else {
    return (
      <Layout>
        <Routes routes={routeConfig} />
      </Layout>
    );
  }
};

export default App;
