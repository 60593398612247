import React from "react";
// import blueLogo from "@assets/images/logo-blue.png";
import "./layout.less";

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="layout__container">
      {/* <div className="layout__header">
        <img src={blueLogo} alt="logo" />
      </div> */}
      {children}
      {/* <div className="layout__background-wrapper"></div> */}
    </div>
  );
};

export default Layout;
