import { useState } from "react";

import CompanyRow from "./companyRow";
import { Button, Input } from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { useFetchCompaniesQuery } from "@services";
import { setSelectedCompanyInStorage } from "@/services/global-storage";
import { ICompany } from "@/types";

import "./apps.less";
import { useTypedSelector } from "@/hooks";
import CompanySelectCarousel from "@components/caurosel";

export interface CompanyListType {
  name: string;
  desc: string;
  continents: string;
  icon: string;
}

const Apps = () => {
  const [
    page,
    // setPage
  ] = useState(1);
  const selectedApp = useTypedSelector(state => state.selected_app);
  const [searchString, setSearchString] = useState<string>("");
  const [selectedCompany, setselectedCompany] = useState<ICompany>();
  const { data: companiesData, isLoading: isFetching } =
    useFetchCompaniesQuery(page);
  const { data } = companiesData || {};
  const onPressNext = () => {
    window.open(selectedApp.applicationLink, "_self");
    // window.open("http://localhost:3000", "_self");
  };

  let handleCompanySelect = (app: any) => {
    setselectedCompany(app);
    setSelectedCompanyInStorage(app);
    onPressNext();
  };
  return (
    <div className="ApplicationSelector  w-full px-12">
      <h2 className="font-bold text-center text-6xl text-themeBlue mt-6 ">
        Select the Company
      </h2>

      {isFetching ? (
        <div className="flex justify-center items-center mt-28">
          <LoadingOutlined className="spinner" />
        </div>
      ) : (
        <CompanySelectCarousel
          onPress={handleCompanySelect}
          companiesList={data && data}
        />
      )}
    </div>
  );
};

export default Apps;
