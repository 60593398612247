import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LoadingOutlined } from "@ant-design/icons";

import "@styles/auth.less";
import { paths } from "@router";
import {
  ErrorServices,
  loadRefreshToken,
  useLoginMutation,
  useLogoutMutation,
} from "@services";
import { BiShow, BiHide } from "react-icons/bi";

import {
  removeUserDateFromSharedStorage,
  setUserDataInSharedStorage,
  removeSelectedCompanyInStorage,
} from "@/services/global-storage";
import { Link } from "react-router-dom";

interface ILoginForm {
  email: string;
  password: string;
  remember: boolean;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Login = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const [login, { isLoading }] = useLoginMutation();
  const [logout] = useLogoutMutation();

  useEffect(() => {
    // when SSO login page opens. logout user from SSO app
    removeUserDateFromSharedStorage();
    removeSelectedCompanyInStorage();
    const refresh = loadRefreshToken();
    logout({ refresh });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (values: ILoginForm) => {
    const { email, password } = values;
    try {
      const res = await login({
        body: {
          email,
          password,
        },
      }).unwrap();
      if (res?.data?.is_one_time_password) {
        history.push(paths.ssoRoute.auth.reset_password);
      } else {
        setUserDataInSharedStorage(res.data.token);
        history.push(paths.ssoRoute.apps.apps);
      }
    } catch (error: any) {
      ErrorServices(error);
    }
  };

  return (
    <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md md:max-w-xl lg:max-w-2xl">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <Formik
          initialValues={{ email: "", password: "", remember: false }}
          validationSchema={validationSchema}
          onSubmit={signIn}
        >
          {() => (
            <Form className="space-y-6">
              <div className="sm:mx-auto sm:w-full sm:max-w-md md:max-w-xl flex items-center justify-between px-2">
                <h2 className="text-2xl font-semibold text-gray-900">Log in</h2>
                <div>
                  {/* <p className="text-themeGrey">
                    Or{" "}
                    <Link to={"/register"} className="text-themeBlue">
                      Create an account
                    </Link>
                  </p> */}
                </div>
              </div>
              <div>
                <div className="mt-1">
                  <Field
                    type="email"
                    name="email"
                    autoComplete="email"
                    placeholder="Email address"
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border bg-themeCore border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-themeBlue focus:border-themeBlue focus:z-10 sm:text-sm"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>
              <div>
                <div className="">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <BiShow className="h-5 w-5 text-gray-400" />
                    </div>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      autoComplete="current-password"
                      placeholder="Enter your password"
                      className="appearance-none rounded-md relative block w-full px-3 py-2 bg-themeCore border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-themeBlue focus:border-themeBlue focus:z-10 sm:text-sm"
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <BiHide className="h-5 w-5 text-gray-400" />
                      ) : (
                        <BiShow className="h-5 w-5 text-gray-400" />
                      )}
                    </div>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="remember_me"
                  className="flex items-center cursor-pointer ml-1"
                >
                  <Field
                    type="checkbox"
                    id="remember_me"
                    name="remember_me"
                    className="h-4 w-4 !bg-themeBlue text-themeBlue focus:ring-themeBlue border-gray-300 rounded"
                  />
                  <span className="ml-2 text-sm text-gray-900">
                    Remember me
                  </span>
                </label>
              </div>

              <div>
                <button
                  disabled={isLoading}
                  type="submit"
                  className="group outline-none relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-themeBlue opacity-75 hover:bg-themeBlue hover:opacity-100 transition-all delay-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-themeBlue"
                >
                  {isLoading ? (
                    <LoadingOutlined className="spinner" />
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="mt-6">
          <Link
            to={paths.ssoRoute.auth.forgot_password}
            className="font-semibold text-themeBlue  cursor-pointer"
          >
            Forgot password?
          </Link>
          {/* <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  Or log in with
                </span>
              </div>
            </div>
            <div className="mt-6 grid grid-cols-2 gap-3">
              <div className="flex items-center gap-2 text-red-500 w-full  justify-center px-8 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium  bg-white hover:bg-red-500 hover:text-white hover:border-red-500 transition-all delay-0 cursor-pointer">
                <FaGooglePlusG className="text-xl " />
                <p>google</p>
              </div>
              <div className="flex items-center gap-2 text-themeBlue w-full  justify-center px-8 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium  bg-white hover:bg-themeBlue hover:text-white hover:border-themeBlue transition-all delay-0 cursor-pointer">
                <FaFacebookSquare className="text-lg" />
                <p>facebook</p>
              </div>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
