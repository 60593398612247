const CREATE_SECTOR = "CREATE_SECTOR";
const VIEW_SECTOR = "VIEW_SECTOR";
const DELETE_SECTOR = "DELETE_SECTOR";
const UPDATE_SECTOR = "UPDATE_SECTOR";
const CREATE_INDUSTRY = "CREATE_INDUSTRY";
const VIEW_INDUSTRY = "VIEW_INDUSTRY";
const DELETE_INDUSTRY = "DELETE_INDUSTRY";
const UPDATE_INDUSTRY = "UPDATE_INDUSTRY";
const CREATE_SUB_INDUSTRY = "CREATE_SUB_INDUSTRY";
const VIEW_SUB_INDUSTRY = "VIEW_SUB_INDUSTRY";
const DELETE_SUB_INDUSTRY = "DELETE_SUB_INDUSTRY";
const UPDATE_SUB_INDUSTRY = "UPDATE_SUB_INDUSTRY";
const CREATE_JOB_SUB_FUNCTION = "CREATE_JOB_SUB_FUNCTION";
const VIEW_JOB_SUB_FUNCTION = "VIEW_JOB_SUB_FUNCTION";
const UPDATE_JOB_SUB_FUNCTION = "UPDATE_JOB_SUB_FUNCTION";
const DELETE_JOB_SUB_FUNCTION = "DELETE_JOB_SUB_FUNCTION";
const CREATE_TOM_USER = "CREATE_TOM_USER";
const VIEW_TOM_USER = "VIEW_TOM_USER";
const UPDATE_TOM_USER = "UPDATE_TOM_USER";
const ENABLE_DISABLE_TOM_USER = "ENABLE_DISABLE_TOM_USER";
const DELETE_TOM_USER = "DELETE_TOM_USER";
const CREATE_COMPANY_USER = "CREATE_COMPANY_USER";
const VIEW_COMPANY_USER = "VIEW_COMPANY_USER";
const UPDATE_COMPANY_USER = "UPDATE_COMPANY_USER";
const ENABLE_DISABLE_COMPANY_USER = "ENABLE_DISABLE_COMPANY_USER";
const DELETE_COMPANY_USER = "DELETE_COMPANY_USER";
const CREATE_OFFER = "CREATE_OFFER";
const VIEW_OFFER = "VIEW_OFFER";
const UPDATE_OFFER = "UPDATE_OFFER";
const DELETE_OFFER = "DELETE_OFFER";
const CREATE_JOB_GRADE = "CREATE_JOB_GRADE";
const VIEW_JOB_GRADE = "VIEW_JOB_GRADE";
const UPDATE_JOB_GRADE = "UPDATE_JOB_GRADE";
const DELETE_JOB_GRADE = "DELETE_JOB_GRADE";
const CREATE_SALARY_RANGE = "CREATE_SALARY_RANGE";
const VIEW_SALARY_RANGE = "VIEW_SALARY_RANGE";
const UPDATE_SALARY_RANGE = "UPDATE_SALARY_RANGE";
const DELETE_SALARY_RANGE = "DELETE_SALARY_RANGE";
const CREATE_CASH_ALLOWANCE = "CREATE_CASH_ALLOWANCE";
const VIEW_CASH_ALLOWANCE = "VIEW_CASH_ALLOWANCE";
const UPDATE_CASH_ALLOWANCE = "UPDATE_CASH_ALLOWANCE";
const DELETE_CASH_ALLOWANCE = "DELETE_CASH_ALLOWANCE";
const CREATE_COMPANY_STI = "CREATE_COMPANY_STI";
const VIEW_COMPANY_STI = "VIEW_COMPANY_STI";
const UPDATE_COMPANY_STI = "UPDATE_COMPANY_STI";
const DELETE_COMPANY_STI = "DELETE_COMPANY_STI";
const CREATE_COMPANY_LTI = "CREATE_COMPANY_LTI";
const VIEW_COMPANY_LTI = "VIEW_COMPANY_LTI";
const UPDATE_COMPANY_LTI = "UPDATE_COMPANY_LTI";
const DELETE_COMPANY_LTI = "DELETE_COMPANY_LTI";
const CREATE_INTERNAL_PAYROLL = "CREATE_INTERNAL_PAYROLL";
const VIEW_INTERNAL_PAYROLL = "VIEW_INTERNAL_PAYROLL";
const UPDATE_INTERNAL_PAYROLL = "UPDATE_INTERNAL_PAYROLL";
const DELETE_INTERNAL_PAYROLL = "DELETE_INTERNAL_PAYROLL";
const CREATE_MARKET_DATA = "CREATE_MARKET_DATA";
const VIEW_MARKET_DATA = "VIEW_MARKET_DATA";
const UPDATE_MARKET_DATA = "UPDATE_MARKET_DATA";
const DELETE_MARKET_DATA = "DELETE_MARKET_DATA";
const VIEW_BUSINESS_UNIT = "VIEW_BUSINESS_UNIT";
const CREATE_BUSINESS_UNIT = "CREATE_BUSINESS_UNIT";
const UPDATE_BUSINESS_UNIT = "UPDATE_BUSINESS_UNIT";
const DELETE_BUSINESS_UNIT = "DELETE_BUSINESS_UNIT";
const VIEW_REGION = "VIEW_REGION";
const DELETE_REGION = "DELETE_REGION";
const UPDATE_REGION = "UPDATE_REGION";
const CREATE_REGION = "CREATE_REGION";

export {
  CREATE_SECTOR,
  VIEW_SECTOR,
  DELETE_SECTOR,
  UPDATE_SECTOR,
  CREATE_INDUSTRY,
  VIEW_INDUSTRY,
  DELETE_INDUSTRY,
  UPDATE_INDUSTRY,
  CREATE_SUB_INDUSTRY,
  VIEW_SUB_INDUSTRY,
  DELETE_SUB_INDUSTRY,
  UPDATE_SUB_INDUSTRY,
  CREATE_JOB_SUB_FUNCTION,
  VIEW_JOB_SUB_FUNCTION,
  DELETE_JOB_SUB_FUNCTION,
  UPDATE_JOB_SUB_FUNCTION,
  CREATE_TOM_USER,
  VIEW_TOM_USER,
  UPDATE_TOM_USER,
  ENABLE_DISABLE_TOM_USER,
  DELETE_TOM_USER,
  CREATE_COMPANY_USER,
  VIEW_COMPANY_USER,
  UPDATE_COMPANY_USER,
  ENABLE_DISABLE_COMPANY_USER,
  DELETE_COMPANY_USER,
  CREATE_OFFER,
  VIEW_OFFER,
  UPDATE_OFFER,
  DELETE_OFFER,
  CREATE_JOB_GRADE,
  VIEW_JOB_GRADE,
  UPDATE_JOB_GRADE,
  DELETE_JOB_GRADE,
  CREATE_SALARY_RANGE,
  VIEW_SALARY_RANGE,
  UPDATE_SALARY_RANGE,
  DELETE_SALARY_RANGE,
  CREATE_CASH_ALLOWANCE,
  VIEW_CASH_ALLOWANCE,
  UPDATE_CASH_ALLOWANCE,
  DELETE_CASH_ALLOWANCE,
  CREATE_COMPANY_STI,
  VIEW_COMPANY_STI,
  UPDATE_COMPANY_STI,
  DELETE_COMPANY_STI,
  CREATE_COMPANY_LTI,
  VIEW_COMPANY_LTI,
  UPDATE_COMPANY_LTI,
  DELETE_COMPANY_LTI,
  CREATE_INTERNAL_PAYROLL,
  VIEW_INTERNAL_PAYROLL,
  UPDATE_INTERNAL_PAYROLL,
  DELETE_INTERNAL_PAYROLL,
  CREATE_MARKET_DATA,
  VIEW_MARKET_DATA,
  UPDATE_MARKET_DATA,
  DELETE_MARKET_DATA,
  VIEW_BUSINESS_UNIT,
  CREATE_BUSINESS_UNIT,
  UPDATE_BUSINESS_UNIT,
  DELETE_BUSINESS_UNIT,
  VIEW_REGION,
  DELETE_REGION,
  UPDATE_REGION,
  CREATE_REGION,
};
