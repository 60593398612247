import { FC } from "react";
import { Redirect, Route } from "react-router-dom";

import { paths } from ".";
import { useTypedSelector } from "@hooks";
import { IRoute } from "./types";

const RouteWithSubRoutes: FC<IRoute> = route => {
  const { user } = useTypedSelector(state => state.auth);
  const isAuthenticated = user?.id;
  localStorage.setItem("prevPath", window.location.pathname);
  console.log({ route }, { user }, { isAuthenticated });
  return (
    <Route
      path={route.path}
      render={props =>
        route.isPrivate ? (
          isAuthenticated ? (
            <route.component {...props} routes={route.routes} />
          ) : (
            <Redirect to={paths.ssoRoute.auth.login} />
          )
        ) : (
          <route.component {...props} routes={route.routes} />
        )
      }
    />
  );
};

export default RouteWithSubRoutes;
