import "./appRow.less";

import { IAppType } from "@/types";
import ApplicationLogo from "@assets/images/app.png";

interface IAppRow {
  app: IAppType;
  isSelected: boolean;
  onAppSelect: (app: IAppType) => void;
}

const AppRow = (props: IAppRow) => {
  const { app, isSelected, onAppSelect } = props;
  const { name, desc, initials,disabled } = app;
  return (
    <div
      onClick={() => onAppSelect(app)}
      style={{
        width: "20rem",
        height: "8rem",
      }}
      className={`AppBox group flex items-center gap-3 px-4  ${disabled ? "bg-themeGrey hover:bg-themeGrey cursor-auto":"hover:bg-themeBlue  cursor-pointer"} hover:bg-themeBlue hover:shadow-lg rounded-md delay-0 transition-all hover:transition-all`}
    >
      <div className={`iconContainer group-hover:bg-white  ${disabled ? "hover:bg-themeGrey":"bg-themeBlue px-2"} w-28 group-hover:transform group-hover:scale-[1.05] rounded-md shadow-lg`}>
        <button className={`${disabled ? "appIcon1":'appIcon' } block`}>
          <img
            src={ApplicationLogo}
            className={`${disabled ? "appIconImg1":'appIconImg' }  w-full h-full`}
            alt=""
          />
        </button>
      </div>
      <div className="AppDesc w-3/4">
        <div className={`title text-2xl  ${disabled ? "text-white":" text-themeBlue"} group-hover:text-white group-hover:transition-all group-hover:delay-0 font-semibold`}>
          {name || ""}
        </div>
        <div className="desc text-xs text-themeGrey">{desc || ""}</div>
      </div>
    </div>
    // <div
    //   className={`app__row ${isSelected ? "selected" : ""} ${
    //     app.disabled ? "disabled" : ""
    //   }`}
    //   onClick={() => onAppSelect(app)}
    // >
    //   <div className="app__initials">{initials}</div>
    //   <div>
    //     <div className="app__name">{name}</div>
    //     <div className="app__desc">{desc}</div>
    //   </div>
    // </div>
  );
};

export default AppRow;
