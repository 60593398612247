import React, { useState } from "react";
import { Button, message, Typography } from "antd";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "@styles/auth.less";
import "./forgotPassword.less";
import { ErrorServices, useResetPasswordMutation } from "@services";
import { paths } from "@router";

interface IProps {
  sendLink: (email: string) => Promise<any>;
  email?: string;
}

interface ISendLinkView extends IProps {
  setIsEmailSent: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

const Timer = ({
  minutes,
  seconds,
  completed,
}: {
  minutes: number;
  seconds: number;
  completed: boolean;
}) => {
  const min = minutes !== 1 ? "00" : "01";
  const sec = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <div className="timer-view">
      <h1 className="timer-minute">{min}</h1>
      <h1 className="timer-seconds">{sec}</h1>
    </div>
  );
};

const SendLinkView = ({
  setIsEmailSent,
  isLoading,
  sendLink,
}: ISendLinkView) => {
  const onFinish = async (values: { email: string }) => {
    const { email } = values;
    try {
      await sendLink(email);
      message.success("Reset password link sent on your email");
      setIsEmailSent(true);
    } catch (error: any) {
      ErrorServices(error);
    }
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  return (
    <>
      <p className="text-gray-900 text-lg">
        Forgot your{" "}
        <span className="text-xl font-semibold text-themeBlue cursor-pointer">
          Password?
        </span>
      </p>
      <p className="text-rey mt-2">
        Enter your registered email address below and we will send you a link to
        reset your password.
      </p>

      <Formik
        name="forget"
        onSubmit={onFinish}
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <div>
              <div className="mt-8">
                <Field
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="Email address"
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border bg-themeCore border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-themeBlue focus:border-themeBlue focus:z-10 sm:text-sm"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="my-8">
                <button
                  disabled={isLoading}
                  type="submit"
                  className="group outline-none relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-themeBlue opacity-75 hover:bg-themeBlue hover:opacity-100 transition-all delay-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-themeBlue"
                >
                  {isLoading ? (
                    <LoadingOutlined className="spinner" />
                  ) : (
                    "Send link"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {/* <Form.Item wrapperCol={{ span: 24 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="login__btn"
            size="large"
          >
            Send link{" "}
            {isLoading ? (
              <LoadingOutlined className="spinner" />
            ) : (
              <ArrowRightOutlined />
            )}
          </Button>
        </Form.Item> */}
    </>
  );
};

const ResendLinkView = ({ sendLink, email }: IProps) => {
  const [key, setKey] = useState(1);
  const [disabled, setDisabled] = useState(true);

  const resendLink = async () => {
    try {
      await sendLink(email!);
      message.success("Reset password link sent on your email");
      setKey(prev => prev + 1);
      setDisabled(true);
    } catch (error) {
      ErrorServices(error);
    }
  };

  return (
    <div className="resend-link-container">
      <p className="text-gray-900 text-lg">
        Check your{" "}
        <span className="text-xl font-semibold text-themeBlue cursor-pointer">
          Email?
        </span>
      </p>
      <p className="text-rey mt-2">
        Kindly check your email! We have sent you a link to reset your password.
      </p>

      <div className="timer-container">
        <Countdown
          key={key}
          onComplete={() => {
            setDisabled(false);
          }}
          date={Date.now() + 60_000}
          renderer={Timer}
        />

        <p className="text-gray-900 mt-2">Didn’t receive email yet?</p>
      </div>

      <div className="my-8">
        <button
          disabled={disabled}
          type="button"
          onClick={resendLink}
          className={
            disabled
              ? "group outline-none relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-500 bg-gray-300"
              : "group outline-none relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-themeBlue opacity-75 hover:bg-themeBlue hover:opacity-100 transition-all delay-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-themeBlue"
          }
        >
          Resend link
        </button>
      </div>
    </div>
  );
};

const ForgotPassword = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [email, setEmail] = useState("");

  const sendLink = (email: string) => {
    setEmail(email);
    return resetPassword({
      email,
    }).unwrap();
  };

  return (
    // <div className="auth__form__container">
    <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md md:max-w-xl lg:max-w-2xl">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        {!isEmailSent ? (
          <SendLinkView
            sendLink={sendLink}
            isLoading={isLoading}
            setIsEmailSent={setIsEmailSent}
          />
        ) : (
          <ResendLinkView email={email} sendLink={sendLink} />
        )}

        <p className="text-themeGrey">
          Back to{" "}
          <Link
            className="font-semibold text-themeBlue cursor-pointer"
            to={paths.ssoRoute.auth.login}
          >
            Login
          </Link>
        </p>
      </div>
    </div>

    // </div>
  );
};

export default ForgotPassword;
