import { Button as AntButton, Input } from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { ICompany } from "@/types";
import { setSelectedCompanyInStorage } from "@/services/global-storage";
import { useFetchCompaniesQuery } from "@services";
// import { useTypedSelector } from "@/hooks";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { paths } from "@/router";
import CompanyRow from "@/pages/CompanyList/companyRow";
import CompanySelectCarousel from "@components/caurosel";

const SubAdmin = () => {
  const history = useHistory();
  const [
    page,
    // setPage
  ] = useState(1);
  // const selectedApp = useTypedSelector(state => state.selected_app);
  const [searchString, setSearchString] = useState<string>("");
  const [isCompanySelected, setIsCompanySelected] = useState<boolean>(false);
  const [selectedCompany, setselectedCompany] = useState<ICompany>();
  const { data: companiesData, isLoading: isFetching } =
    useFetchCompaniesQuery(page);
  const { data } = companiesData || {};

  const onPressNext = (app: any) => {
    setIsCompanySelected(true);
    history.push(
      `${paths.admin.users.sub_admins.sub_admin}/list/${app?.id}`
    );
  };
  let handleCompanySelect = (app: ICompany) => {
    setselectedCompany(app);
    setSelectedCompanyInStorage(app);
    onPressNext(app);
  };

  if (!isCompanySelected) {
    return (
      <div className="ApplicationSelector  w-full px-12">
        <h2 className="font-bold text-center text-6xl text-themeBlue mt-6 ">
          Select the Company
        </h2>

        {isFetching ? (
          <div className="flex justify-center items-center mt-28">
            <LoadingOutlined className="spinner" />
          </div>
        ) : (
          <CompanySelectCarousel
            onPress={handleCompanySelect}
            companiesList={data && data}
          />
        )}
      </div>
    );
  }
};

export default SubAdmin;
