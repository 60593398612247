import { useEffect, useRef, useState } from "react";
// import Table from "@components/Table";
import { Button, Input, Form, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import CountryPhoneInput, {
  // CountryPhoneInputValue,
  ConfigProvider,
} from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/countries.json";
import "./style.less";
import {
  //ErrorServices,
  useCreateCompanyUserMutation,
  useFetchCompanyUserByIdQuery,
  useUpdateCompanyUserMutation,
  ErrorServices,
} from "@services";
import { useTypedSelector } from "@hooks";
// import {
//   SubAdminModal,
// } from "@types";
import Tabs from "@/components/Tabs";
import TomTab from "./TomTab";
import JeTab from "./JeTab";

const CreateSubAdmin = () => {
  let subAdminForm = useRef<any>(null);
  const [form] = Form.useForm();
  const history = useHistory();
  const { user } = useTypedSelector(state => state.auth);
  const initializeForm = useRef(true);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [tabs, setTabs] = useState<any>([]);
  // const [applicationArray, setApplicationArray] = useState<string[]>(["TOM"]);
  const [phoneNumber, setPhoneNumber] = useState<any>({
    short: "US",
  });
  const [activeTab, setActiveTab] = useState<number>(1);
  const [tomData, setTomData] = useState<any>({
    grant_access: false,
    name: "",
    description: "",
    metafield: {
      business_units: [],
      regions: [],
      countries: [],
      grades: [],
      job_functions: [],
    },
    role_metafield: {
      has_live_pay_tool: false,
    },
    permissions: [],
  });
  const [jeData, setJeData] = useState<any>({
    grant_access: false,
    name: "",
    description: "",
    metafield: {
      business_units: [],
      regions: [],
      countries: [],
      grades: [],
      job_functions: [],
    },
    permissions: [],
  });
  const params: { company_id: string } = useParams();
  const paramsCompanyId = params.company_id;

  const { id: company_id } = user?.company || { id: paramsCompanyId };
  const [createCompanyUser, { isLoading }] = useCreateCompanyUserMutation();
  const [updateCompanyUser, { isLoading: isUpdating }] =
    useUpdateCompanyUserMutation();
  const { sub_admin_id } = useParams<{ sub_admin_id?: string }>();
  const { data } = useFetchCompanyUserByIdQuery(
    {
      company_id,
      id: sub_admin_id,
    },
    { skip: !sub_admin_id, refetchOnMountOrArgChange: true }
  );
  const { data: selected_user } = data || {};

  useEffect(() => {
    if (
      user?.company?.applications?.length &&
      tabs?.length === 0 &&
      user?.role !== "TOM_MASTER_USER"
    ) {
      const enableTom =
        user?.company?.applications?.findIndex(elm => elm?.enum === "TOM") !==
        -1;
      const enableJE =
        user?.company?.applications?.findIndex(elm => elm?.enum === "JE") !==
        -1;
      console.log(enableJE, enableTom);
      if (enableJE) setTabs([...tabs, { id: 2, title: "Job Evaluation" }]);
      if (enableTom) setTabs([...tabs, { id: 1, title: "Tom" }]);

      if (enableJE && !enableTom) setActiveTab(2);
    } else {
      setTabs([
        { id: 1, title: "Tom" },
        { id: 2, title: "Job Evaluation" },
      ]);
    }
  }, [user]);
  console.log(tabs);

  useEffect(
    () => {
      if (selected_user) {
        console.log(selected_user);
        // const applications = selected_user.applications.map(
        //   (item: any) => item.enum
        // );
        // setApplicationArray(applications);
        setFirstName(selected_user.first_name);
        setLastName(selected_user.last_name);
        setPhoneNumber({
          code: selected_user?.phone_code,
          phone: selected_user?.phone_number,
        });
        setEmail(selected_user.email);
        setTomData({
          grant_access: selected_user?.roles?.TOM?.grant_access,
          name: selected_user?.roles?.TOM?.name,
          description: selected_user?.roles?.TOM?.description,
          metafield: {
            business_units:
              selected_user?.roles?.TOM?.metafield?.business_units.map(
                (elm: any) => elm.id
              ),
            regions: selected_user?.roles?.TOM?.metafield?.regions.map(
              (elm: any) => elm.id
            ),
            countries: selected_user?.roles?.TOM?.metafield?.countries.map(
              (elm: any) => elm.id
            ),
            grades: selected_user?.roles?.TOM?.metafield?.grades.map(
              (elm: any) => elm.id
            ),
            job_functions:
              selected_user?.roles?.TOM?.metafield?.job_functions.map(
                (elm: any) => elm.id
              ),
          },
          role_metafield: {
            has_live_pay_tool:
              selected_user?.roles?.TOM?.role_metafield?.has_live_pay_tool,
          },
          permissions: selected_user?.roles?.TOM?.permissions.map(
            (elm: any) => elm.name
          ),
        });
        setJeData({
          grant_access: selected_user?.roles?.JE?.grant_access,
          name: selected_user?.roles?.JE?.name,
          description: selected_user?.roles?.JE?.description,
          metafield: {
            business_units:
              selected_user?.roles?.JE?.metafield?.business_units.map(
                (elm: any) => elm.id
              ),
            regions: selected_user?.roles?.JE?.metafield?.regions.map(
              (elm: any) => elm.id
            ),
            countries: selected_user?.roles?.JE?.metafield?.countries.map(
              (elm: any) => elm.id
            ),
            grades: selected_user?.roles?.JE?.metafield?.grades.map(
              (elm: any) => elm.id
            ),
            job_functions:
              selected_user?.roles?.JE?.metafield?.job_functions.map(
                (elm: any) => elm.id
              ),
          },
          role_metafield: {
            has_live_pay_tool:
              selected_user?.roles?.JE?.role_metafield?.has_live_pay_tool,
          },
          permissions: selected_user?.roles?.JE?.permissions.map(
            (elm: any) => elm.name
          ),
        });

        const values = {
          first_name: selected_user.first_name,
          last_name: selected_user.last_name,
          phoneNumber: {
            code: selected_user?.phone_code,
            phone: selected_user?.phone_number,
          },
          email: selected_user.email,
          remember: true,
        };
        form.setFieldsValue(values);
        initializeForm.current = false; // prevent form re-initilization when any request is refetched.
      }
    },
    // eslint-disable-next-line
    [form, selected_user]
  );

  const handleTabData = (key: string, data: any) => {
    if (key === "tom") {
      setTomData(data);
    } else {
      setJeData(data);
    }
  };

  const onSubmit = async (payload: any) => {
    let appArr = [];
    const tempRoles: any = {};
    if (tomData?.grant_access) {
      appArr.push("TOM");
      tempRoles.TOM = { ...tomData };
    }
    if (jeData?.grant_access) {
      appArr.push("JE");
      tempRoles.JE = { ...jeData };
    }

    const roleObj = jeData?.grant_access ? { ...jeData } : { ...tomData };

    try {
      const data = {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber.phone,
        phone_code: phoneNumber.code,
        applications: appArr,
        email: email,
        role: roleObj,
        roles: { ...tempRoles },
      };

      if (sub_admin_id) {
        await updateCompanyUser({
          body: data,
          company_id,
          id: selected_user?.id,
        }).unwrap();
      } else {
        await createCompanyUser({ body: data, company_id }).unwrap();
      }
      subAdminForm.current.resetFields();
      message.success(
        `Sub Admin has been successfully ${
          sub_admin_id ? "updated" : "created"
        }.`
      );
      history.goBack();
    } catch (error) {
      console.log(error);
      ErrorServices(error);
    }
  };

  return (
    <>
      <h1 className="form_heading">{`${
        sub_admin_id ? "Update" : "Create"
      } Sub-Admin`}</h1>
      <ConfigProvider locale={en}>
        <Form
          name="sub_admin"
          form={form}
          ref={subAdminForm}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
          layout="vertical"
          className="create__company__container"
        >
          <div className="sub__admin_details_container">
            <h1 className="section__heading">Basic information</h1>
            <div className="form__section">
              <div className="form__section_container">
                <div className="contact__person__sub_container">
                  <Form.Item
                    className="form__item contact__person_item"
                    label={<label className="input__label">First name</label>}
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter first name",
                      },
                    ]}
                  >
                    <Input
                      className="form__input"
                      type="text"
                      placeholder="Enter first name here..."
                      name="first_name"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    className="form__item contact__person_item"
                    label={<label className="input__label">Last name</label>}
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter last name",
                      },
                    ]}
                  >
                    <Input
                      className="form__input"
                      name="last_name"
                      type="text"
                      placeholder="Enter last name here..."
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </Form.Item>
                </div>

                <div className="contact__person__sub_container">
                  <Form.Item
                    className="form__item contact__person_item"
                    label={
                      <label className="input__label">Contact number</label>
                    }
                    name="phoneNumber"
                    rules={[
                      {
                        required: false,
                        message: "Please enter contact number",
                      },
                    ]}
                  >
                    <ConfigProvider locale={en}>
                      <CountryPhoneInput
                        name="phoneNumber"
                        value={{
                          phone: phoneNumber?.phone,
                          code: phoneNumber?.code || 92,
                        }}
                        onChange={v => {
                          setPhoneNumber(v);
                        }}
                      />
                    </ConfigProvider>
                  </Form.Item>

                  <Form.Item
                    className="form__item contact__person_item"
                    label={
                      <label className="input__label">Email address</label>
                    }
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter email address",
                      },
                    ]}
                  >
                    <Input
                      className="form__input"
                      name="email"
                      type="text"
                      placeholder="Enter email address here..."
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <div className="sub__admin_details_container">
            <h1 className="section__heading margin__bottom__0">
              Define Offer Modeller Extended Permissions
            </h1>
            <div
              style={{ margin: "0 20px", borderBottom: "0.75px solid #d9d9d9" }}
            >
              <Tabs
                route={false}
                tabs={tabs}
                selectedTabId={activeTab}
                onClickTab={setActiveTab}
              />
            </div>
          </div>

          {activeTab === 1 && (
            <TomTab TomData={tomData} createDataCallback={handleTabData} />
          )}
          {activeTab === 2 && (
            <JeTab JeData={jeData} createDataCallback={handleTabData} />
          )}

          <div className="form__submit__section">
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={false}
                size="large"
                loading={isLoading || isUpdating}
              >
                {`${sub_admin_id ? "Update" : "Create"} Sub-Admin`}
              </Button>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                className="login__btn"
                size="large"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </Form>
      </ConfigProvider>
    </>
  );
};

export default CreateSubAdmin;
