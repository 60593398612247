import { paths } from "@router";
// import { permissions } from "@/router";
import { BiBuildings } from "react-icons/bi";
import { BsBriefcase } from "react-icons/bs";
import { LuUsers } from "react-icons/lu";



export const tabs = [
  {
    id: 1,
    title: <span className="flex items-center gap-2 "><BiBuildings className="text-lg" /> Company details</span>,
    path: paths.admin.users.companies.profile.details,
  },
  {
    id: 2,
    title: <span className="flex items-center gap-2">
      <BsBriefcase className="text-lg" />
      Business units
    </span>,
    path: paths.admin.users.companies.profile.business_units.listing,
    // permission : permissions.VIEW_BUSINESS_UNIT,
  },
  {
    id: 3,
    title: <span className="flex  items-center gap-2">
      <LuUsers className="text-lg" />
      Regions
    </span>,
    path: paths.admin.users.companies.profile.regions,
    // permission : permissions.VIEW_REGION,
  },
];
