import React, { useState } from "react";
import Slider from "react-slick";
import { ICompany } from "@/types";

import "./customcarousel.css";

function SampleNextArrow(props: any) {
  const { className, onClick } = props;
  return <div className={className + " slick-next"} onClick={onClick} />;
}

function SamplePrevArrow(props: any) {
  const { className, onClick } = props;
  return <div className={className + " slick-prev"} onClick={onClick} />;
}

interface CompanySelectCarouselProps {
  companiesList: ICompany[];
  onPress: (company: ICompany) => void;
}

const CompanySelectCarousel: React.FC<CompanySelectCarouselProps> = ({
  companiesList,
  onPress,
}) => {
  const [activeCompany, setActiveCompany] = useState<ICompany | null>(null);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1556,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1336,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleSelect = (company: ICompany) => {
    setActiveCompany(company);
    onPress(company);
  };

  return (
    <div className="CompanySelectCarousel mt-10">
      {companiesList.length > 0 ? (
        <Slider {...settings}>
          {companiesList.map((company, i) => {
            return (
              <div
                // style={{ width: 300 }}
                key={company.id}
                onClick={() => setActiveCompany(company)}
                className="CompanyCard group cursor-pointer mx-2 rounded-md"
              >
                <div
                  // style={{ width: "20rem" }}
                  className={`logoContainer border-4 border-white p-9 min-w-44 2xl:min-w-[20rem]  bg-white rounded-lg transition-all delay-0 hover:bg-green-400 ${
                    activeCompany?.id === company.id &&
                    "border-4 border-themeOrange !bg-teal-400 !p-8"
                  }`}
                >
                  <img
                    src={company.large_logo_url}
                    className="branImg object-contain h-72"
                    alt="company logo"
                  />
                  <h1 className="font-bold text-md">{company.name}</h1>
                </div>
                <button
                  style={{ width: "100%" }}
                  onClick={() => handleSelect(company)}
                  className="selectBtn bg-themeOrange  transition-all delay-0 text-white rounded mt-10 py-3 px-5 "
                >
                  Select
                </button>
              </div>
            );
          })}
        </Slider>
      ) : (
        <div className="skeleton flex">
          {[...new Array(4)].map((_, index) => (
            <div
              style={{
                width: "22rem",
              }}
              key={index}
              className="CompanyCardgroup cursor-pointer mx-2 rounded-md"
            >
              <div
                className={`logoContainer p-9 bg-white rounded-lg h-80 animate-pulse`}
              ></div>
              <button className="bg-white text-white rounded mt-10 py-3 px-5 w-full animate-pulse">
                Select
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CompanySelectCarousel;
